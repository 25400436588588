import axios from 'axios'
import { Toast } from 'vant'

const request = axios.create({
  baseURL: 'https://daily.tzyl12349.com/prod-api/',
  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => Promise.reject(err)
)

// //响应拦截器
request.interceptors.response.use(
  (res) => {
    if (res.data.status !== true) {
      // console.log(res.data.message)
      Toast(res.data.msg)
      // 返回 错误的promise
      return Promise.reject(res.data)
    }
    return res
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default request

export async function get(url, params) {
  let { data } = await request.get(url, { params })
  return data
}
export async function post(url, params) {
  let { data } = await request.post(url, params)
  return data
}
