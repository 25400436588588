<template>
  <div id="app" class="demo">
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.demo {
  /* box-shadow: inset; */
  /* border: 1px solid; */
  background-color: rgb(250, 250, 251);
  height: 100%;
}
</style>