import Vue from 'vue'
import App from './App.vue'
import scheduleCalendar from './scheduleCalendar'
import Vant from 'vant'
import 'vant/lib/index.css'
import router from './route'

Vue.component(scheduleCalendar.name, scheduleCalendar)

Vue.use(Vant)

new Vue({
  router,
  el: '#app',
  render: (h) => h(App)
})
