import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=8f051512&scoped=true"
import script from "./layout.vue?vue&type=script&lang=js"
export * from "./layout.vue?vue&type=script&lang=js"
import style0 from "./layout.vue?vue&type=style&index=0&id=8f051512&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_iyla2hhcu74gr7py6ctpn33cqi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f051512",
  null
  
)

export default component.exports