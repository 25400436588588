<template>
    <div>
        <!-- <van-nav-bar :title="activeTitle" fixed /> -->
        <div class="main">
            <!-- 二级路由-挂载点 -->
            <router-view></router-view>
        </div>
        <!-- <van-tabbar route class="footer">
            <van-tabbar-item replace to="/layout/Main">
                <van-icon name="weapp-nav" />
                日历
            </van-tabbar-item>
            <van-tabbar-item replace to="/layout/tabble" icon="search">施工进度</van-tabbar-item>
        </van-tabbar> -->
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            // activeTitle: this.$route.meta.title, // "默认"顶部导航要显示的标题 (默认获取当前路由对象里的meta中title值)
        };
    },
    // 路由切换 - 侦听$route对象改变
    watch: {
        /* $route() {
          this.activeTitle = this.$route.meta.title; // 提取切换后路由信息对象里的title显示
        }, */
    },
};
</script>
  
<style scoped>
/* 中间内容区域 - 容器样式(留好上下导航所占位置) */
.main {
    padding-top: 0.625rem;
    padding-bottom: 3.125rem;
}

.box {
    width: 80%;
    height: 80%;
    background-color: skyblue;
}

/* .footer {
    display: flex;
  } */
</style>