<template>
    <div class="test">
        <div class="demo">
            <h1 class="demo-title">日历</h1>
            <van-button type="info" @click="back" class="back">back</van-button>
            <schedule-calendar :events="events" :dateItemRender="itemRender"
                @event-dragend="changeDate"></schedule-calendar>
        </div>
    </div>
</template>
<script>
import { queryAll } from '@/utils/api'
export default {
    data() {
        return {
            demo: [],
            events: [],
            /* events: [{
                id: '001',
                date: '2023/11/6',
                text: '111',
                status: 1
            }], */
            itemRender(item) {
                const h = this.$createElement
                return h('span', item.text)
            },
        }
    },
    methods: {
        processArrayData(data) {
            let eves = []
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (item.id && item.projectId && item.project) {
                    if (item.projectTime) {
                        const date = new Date(item.projectTime);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1;
                        const day = date.getDate();
                        item.date = `${year}/${month}/${day}`;
                    }
                    item.text = item.project;
                    item.status = item.status || 1;
                    eves.push(item)
                }
            }
            return eves;
        },
        async getEvents() {
            let { data } = await queryAll()
            console.log(data, 'querydata');
            this.events = this.processArrayData(data)
            console.log(this.events, 'events');
        },
        test() {
            debugger
            this.demo = this.processArrayData(this.event)
            console.log(this.demo, 'demo');
        },
        back() {
            this.$router.push('/layout')
        },
        changeDate(e, item, date) {
            const updateIndex = this.events.findIndex(ele => ele.id === item.id)
            this.$set(this.events, updateIndex, {
                ...this.events[updateIndex],
                date
            })
        }
    },
    created() {
        // 下面代码将每月的数据 进行按照日拆分 分取到当月
        // 显示为当月的数据
        /* const now = new Date()
        this.events = this.events.map(item => {
            const d = item.date.split('-')[2]
            item.date = `${now.getFullYear()}-${now.getMonth() + 1}-${d}`
            debugger
            return item
        }) */
    },
    mounted() {
        this.getEvents()
        // this.test()
    },
}
</script>
<style lang="less">
html {
    background: #eee;
}

body,
html {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
}

.test {
    height: 100%;
}

.demo {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 30px 30px;
    box-sizing: border-box;
}

.demo-title {
    text-align: center;
}

.back {
    position: absolute;
    top: 1.875rem;
    width: 4.875rem;
}
</style>
  