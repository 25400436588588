<template>
    <div>
        <van-cell>
            <h1 style="text-align: center;">历史施工进度</h1>
        </van-cell>
        <van-panel>
            <van-row class="head">
                <van-button style="width: 4.875rem;" type="info" @click="jump">日历</van-button>
                <van-button style="width: 4.875rem;" class="addclass" type="info" @click="add">新增</van-button>
            </van-row>

            <!-- 人员姓名预览 -->
            <van-grid :gutter="8" class="active">
                <van-grid-item v-for="(obj, index) in nameList" :key="obj.id" style="position: relative;">
                    <van-button icon="delete" :border="false" @click="delName(obj.id)"
                        style="position: absolute; top: 0.625rem; height: 1.875rem; width: 0.625rem; right: 0.0625rem;" />
                    <h3 style="text-align: center;" @click="lookAll(obj.id, obj.name)">{{ obj.name }}</h3>
                    <template v-for="i in 5">
                        <van-row :class="['rowcontent',`schedule-calendar-status_${obj.projectMsgList[i-1].status}`]"
                            @click="lookAll(obj.id, obj.name)" v-if="obj.projectMsgList && obj.projectMsgList.length >= i"
                            :key="i">
                            <span>{{ obj.projectMsgList[i-1].project }}</span> <span style="float: right;">{{
                                obj.projectMsgList[i-1].projectTime }}</span>
                        </van-row>
                        <van-row class="rowcontent" v-else :key="i">
                        </van-row>
                    </template>
                </van-grid-item>
            </van-grid>

        </van-panel>
        <!-- 分页组件 -->
        <div class="pagenation"><van-pagination v-model="pageNo" @change="changePage()" :total-items="total"
                items-per-page="8" :show-page-size="5" force-ellipses />
        </div>
        <!-- 新增人员弹窗组件 -->
        <van-dialog v-model="dialogShow" title="添加人物" show-cancel-button @confirm="handleAdd" @cancel="cancelAdd">
            <van-form @submit="addToname(toAddname)" class="content" ref="addform">
                <van-field v-model="toAddname" name="人物" label="请填写姓名" placeholder="请填写姓名" :rules="rules" />
            </van-form>
        </van-dialog>


        <!-- 人员详细情况 -->
        <van-popup v-model="isShow" title="改造内容" @close="closePop" class="popupclass">
            <van-cell-group inset>
                <van-cell>
                    <van-row style="font-size: 1.25rem;font-weight: 700;text-align: center;">{{ myname }}</van-row>
                    <van-row>
                        <van-button span="6" icon="plus" type="info" size="small" @click="addpro()">新增项目</van-button>
                        <van-button span="6" icon="clock-o" @click="showTime" type="info"
                            size="small">选择日期</van-button></van-row>
                </van-cell>

                <van-grid :gutter="8" :column-num="2" class="active">
                    <van-grid-item>
                        <h3 style="text-align: center;">项目内容</h3>
                        <van-row :class="['rowcontent',`schedule-calendar-status_${obj.status}`]" v-for="obj in ProjectList"
                            :key="obj.id">{{ obj.project
                            }}</van-row>

                    </van-grid-item>
                    <van-grid-item>
                        <h3 style="text-align: center;">改造时间</h3>
                        <van-row :class="['rowcontent',`schedule-calendar-status_${obj.status}`]" v-for="obj in ProjectList"
                            :key="obj.id">{{ obj.projectTime }}
                            <van-button icon="delete" class="delbutton" @click="delproject(obj.id)" />
                        </van-row>

                    </van-grid-item>
                </van-grid>
            </van-cell-group>

            <!-- 分页组件 -->
            <div style="position: absolute; bottom: 0rem; right: 0rem; width: 20rem; "><van-pagination v-model="prjPageNo"
                    @change="prjChangePage()" :total-items="prjTotal" :show-page-size="5" items-per-page="5"
                    force-ellipses />
            </div>
        </van-popup>


        <!-- 新增改造项目组件 -->
        <van-dialog :style="{'width': '500px' , 'min-height': dialogHeight}" v-model="addDialog" title="添加项目"
            show-cancel-button @cancel="cancelAddPrj()" @confirm="handleAddPrj()">
            <van-form ref="addPrjform" @submit="addproject" class="content">
                <van-field v-model="project" name="project" label="请填写名称" placeholder="请填写名称"
                    :rules="[{ required: true}]" />
                <van-field v-model="projectTime" name="projectTime" label="请填写日期" placeholder="请填写日期"
                    @click="isShowCalendar()" :rules="[{ required: true}]" />
                <!-- 状态输入框 -->
                <van-field v-model="projectStatus" readonly @click="choose()" name="projectStatus" label="请填写状态"
                    placeholder="请填写状态" is-link arrow-direction="down" :rules="[{ required: true}]">
                </van-field>
                <span
                    style="content-visibility: hidden;display: inline-block;background-color: skyblue;width: 348px;">11</span>
                <!-- 气泡悬浮框 -->
                <van-popover v-model="showPopover" :offset=[0,0] placement="bottom">
                    <van-cell-group>
                        <van-cell v-for="obj in selectItems" :key="obj.value" @click="selectOption(obj.value)">
                            <!-- <van-col style="width: 30px;"
                                :class="['selectcoltwo',`schedule-calendar-status_${obj.value}`]">{{
                                obj.text}}</van-col> -->
                            <span style="width: 270px; display: inline-block;"
                                :class="['selectcoltwo',`schedule-calendar-status_${obj.value}`]">{{ obj.text }}</span>
                        </van-cell>
                    </van-cell-group>
                </van-popover>

                <!-- 日期新增组件 -->
                <van-calendar :min-date="minDate" :max-date="maxDate" @close="closeCal" color="#1989fa"
                    v-model="showCalendar" :value="projectTime" @confirm="onConfirm" />
            </van-form>

        </van-dialog>

        <!-- 日期展示组件 -->
        <van-calendar color="#1989fa" :min-date="minDate" :max-date="maxDate" v-model="dayShow" type="range"
            position="right" @confirm="dayConfirm" />

    </div>
</template>
<script>
import { getList, queryProjectMsgByTime, addName, addProjectMsg, queryProjectMsgById, deleteProjectMsg, deleteProjectName } from '@/utils/api'
import { Toast } from 'vant'
export default {
    data() {
        return {
            value: '',
            showPopover: false,
            options: [1, 2, 3, 4, 5],
            selectValue: '',
            startDay: '',
            endDay: '',
            toAddname: '', //要去添加的name
            prjPageNo: 1,
            prjPagesize: 5,
            prjTotal: '',
            minDate: new Date(new Date().getFullYear() - 5, new Date().getMonth(), new Date().getDate()), // 当前时间减去5年
            maxDate: new Date(new Date().getFullYear() + 5, new Date().getMonth(), new Date().getDate()), // 当前时间加上5年
            dialogHeight: '250px',
            // 选择的颜色
            selectItems: [
                { value: '1', text: '灰色' },
                { value: '2', text: '黄色' },
                { value: '3', text: '绿色' },
                { value: '4', text: '蓝色' },
                { value: '5', text: '紫色' }
            ],
            showCalendar: false,
            rules: [
                {
                    required: true,
                    message: '请输入内容'
                }
            ],
            //选择的状态
            showPicker: false,
            ProjectList: [], // 详细的改造内容数组
            ProjectMsg: {}, //对应得对象的参数合集
            projectid: '', // 对应人名称的id
            project: '', // 添加项目时的项目名称
            projectTime: '', // 添加项目时的时间名称
            projectStatus: '',  // 添加项目时的状态
            addDialog: false,
            dayShow: false,
            isShow: false,
            myname: '',
            // status: 1,
            statusClass: [],
            dialogShow: false,
            total: '',
            // 当前页数
            pageNo: 1,
            pageSize: 8,
            nameList: [],
            projectMsgList: []
        }
    },
    methods: {
        // 根据开始结束时间发送请求
        async queryPrjByTime() {
            try {
                let { data, msg, totalCount } = await queryProjectMsgByTime({
                    pageNo: this.prjPageNo,
                    pageSize: this.prjPagesize,
                    id: this.projectid,
                    startTime: this.startDay,
                    endTime: this.endDay
                })
                // console.log(totalCount, 'totalCount');
                this.totalCount = totalCount
                Toast(msg)
                this.ProjectList = data
                // console.log(res, 'res');
            } catch (error) {
                Toast.fail('未查询到信息')
                this.startDay = ''
                this.endDay = ''
            }
        },
        selectOption(option) {
            // 处理选中的选项
            // console.log("选中了:", option);
            this.projectStatus = option
            this.showPopover = false; // 选中后关闭气泡
        },
        // 处理选择开始及结速日期
        formatDate(date) {
            return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
        },
        // 处理日期格式
        dayConfirm(date) {
            const [start, end] = date;
            this.dayShow = false;
            this.startDay = this.formatDate(start)
            this.endDay = this.formatDate(end)
            this.queryPrjByTime()
        },
        // 关闭日期组件
        closeCal() {
            this.dialogHeight = '15.625rem'
        },
        isShowCalendar() {
            this.showPopover = false
            this.dialogHeight = '25.625rem'
            this.showCalendar = true
        },
        // 取消新增项目
        cancelAddPrj() {
            this.$refs.addPrjform.resetValidation() // 重置校验 
            this.projectStatus = ''
            this.projectTime = ''
            this.project = ''
            this.showPopover = false
        },
        // 选定颜色
        chooseColor(id) {
            this.projectStatus = id
            this.choose()
        },
        // 开始选择颜色
        choose() {
            this.showPopover = true
        },
        // 提交日期
        onConfirm(date) {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            this.projectTime = `${year}/${month}/${day}`;
            this.dialogHeight = '15.625rem'
            this.showCalendar = false;
        },
        // id删除人员名称
        delName(id) {
            this.$dialog
                .confirm({
                    title: '提示',
                    message: '是否删除？'
                })
                .then(() => {
                    // 用户点击确认
                    deleteProjectName({
                        id: id
                    })
                        .then(() => {
                            this.$toast.success('删除成功');
                            this.getProjectNameList()
                        })
                        .catch(error => {
                            this.$toast.fail('删除失败');
                        });
                })
                .catch(() => {
                    // 用户点击取消或关闭对话框
                    this.$toast('取消删除');
                });
        },
        // 关闭弹窗
        closePop() {
            this.startDay = ''
            this.endDay = ''
            this.ProjectList = false
        },
        // 人员改造项目页数改变
        prjChangePage() {
            if (this.startDay && this.endDay) {
                this.queryPrjByTime()
            } else {
                this.lookAll(this.projectid, this.myname)
            }

        },
        // 页数的改变
        changePage() {
            this.getProjectNameList()
        },
        // 查询人员列表
        async getProjectNameList() {
            try {
                let { data, totalCount } = await getList({
                    pageSize: this.pageSize,
                    pageNo: this.pageNo,
                })
                this.nameList = data
                console.log(this.nameList, 'namelist');
                this.total = totalCount
            } catch (error) {
                console.log(error, 'error');
            }
        },
        // 删除项目
        async delproject(id) {
            let { status } = await deleteProjectMsg({
                id: id
            })
            if (status) {
                this.getProjectNameList()
                this.lookAll(this.projectid, this.myname)
                Toast.success('删除成功')
            }
        },
        // 打开add项目弹窗
        addpro() {
            this.addDialog = true
        },
        // 新增改造项目
        async addproject() {
            this.ProjectMsg.projectId = this.projectid
            this.ProjectMsg.project = this.project
            this.ProjectMsg.projectTime = this.projectTime
            this.ProjectMsg.status = this.projectStatus
            let { msg, status } = await addProjectMsg(this.ProjectMsg)
            if (status) {
                Toast.success(msg)
                this.getProjectNameList()
                this.lookAll(this.projectid, this.myname)
                this.projectStatus = ''
                this.projectTime = ''
                this.project = ''
            } else { }
        },
        showTime() {
            this.dayShow = true
        },
        async lookAll(id, name) {
            // 发起查询详细改造项目请求
            this.isShow = true
            this.projectid = id
            this.myname = name
            try {
                let { data, totalCount } = await queryProjectMsgById({
                    id: id,
                    pageNo: this.prjPageNo,
                    pageSize: this.prjPagesize,
                })
                this.ProjectList = data
                this.prjTotal = totalCount
            } catch (error) {
                console.log(error, 'error');
            }
        },
        // 取消添加时
        cancelAdd() {
            this.myname = ''
            this.$refs.addform.resetValidation()
        },
        handleAdd() {
            if (this.toAddname) {
                this.addToname()
            } else {
                Toast.fail('您未输入名字')
            }
        },
        handleAddPrj() {
            if (this.projectTime && this.projectStatus && this.project) {
                this.addproject()
            } else {
                Toast.fail('信息未提供完整')
                this.cancelAddPrj()
            }
        },
        // 添加人员（姓名）
        async addToname() {
            try {
                let { status } = await addName({
                    name: this.toAddname
                })
                if (status) {
                    Toast.success('新增成功')
                    this.toAddname = ''
                    this.getProjectNameList()
                } else {
                    Toast.fail('新增失败')
                }
            } catch (error) {
                console.log(error);
            }
        },
        add() {
            this.dialogShow = true
        },
        jump() {
            this.$router.push({
                path: '/layout/rili',
            })
        }
    },
    mounted() {
        this.getProjectNameList()
    }
}
</script>


<style lang="less" scoped>
.selectrow {
    line-height: 1.875rem;
    height: 1.875rem;
    width: 31.25rem;
    padding-left: 0rem;

    .selectcolone {
        text-align: center;
        width: 3.75rem;
    }

    .selectcoltwo {
        margin-top: 0.125rem;
        margin-left: 2.4375rem;
        width: 326px;
        // background-color: skyblue;
    }
}

.project-content {
    margin-top: 0.625rem;
    display: flex;
    justify-content: space-around;
    font-size: 1.125rem;
    font-weight: 500;
    // padding-left: 3.3125rem
}

.active :hover {
    transform: translateY(-0.0325rem);
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 0, 0, .2), 0 -0.1875rem 0.5rem rgba(0, 0, 0, .2);
}


.spancontent {
    font-size: 1rem;
    color: white;
    // border-radius: 10%;
}

// 颜色控制less
.schedule-calendar- {
    &item {
        margin: 0.0625rem;
        opacity: 65%;
        // opacity: 0.2;
        font-size: 1.125rem;
        color: white;
        font-weight: 700;
    }

    &status_1 {
        background: #607d8b;
    }

    &status_2 {
        background: #ff9800;
    }

    &status_3 {
        background: #4caf50;
    }

    &status_4 {
        background: #00bcd4;
    }

    &status_5 {
        background: #f232e2;
    }

    &status_6 {
        background: #00bcd4;
    }

    &status_7 {
        background: #4caf50;
    }

    &status_8 {
        background: #cddc39;
    }

    &status_9 {
        background: #ff9800;
    }

    &status_10 {
        background: #607d8b;
    }
}

.popupclass {
    width: 43.75rem;
    height: 33.75rem;
    // background-color: pink;
    padding: 1.3rem;
    border: 0.124rem solid #f7f8f9;
}

.rowcontent {
    text-align: justify;
    line-height: 1.875rem;
    width: 100%;
    height: 30px;
    // background-color: skyblue;
    border: 0.0938rem solid #f7f8f9;
    // position: absolute;
}

.rowcontent .delbutton {
    float: right;
    width: 10px;
    height: 30px;
    // margin-right: 10px;
}


.colcontent {
    background-color: pink;
    // border: 1px solid;
    // margin-right: 1px;
    // z-index: 800;
    height: 100%;
}


.rowbody {
    background-color: skyblue;
    margin-left: 0.625rem;
    border: 1px solid #f7f8f9;
    margin-top: 0.625rem;
    /* padding-left: 0.6870rem; 
}

.colbox {
    display: flex;
    background-color: pink;
    width: 17.75rem;
    height: 17.75rem;
    margin: .625rem;
    text-align: center;
    /* line-height: 18.75rem; */
    font-size: larger;
    font-weight: 700;
}

.addclass {
    right: .4375rem;
    position: absolute;
}

.head {
    /* display: flex; */
    position: relative;
    padding-left: 0.6870rem;
    margin-bottom: 10px;
    /* padding-right: 5px; */
}

.panel {
    margin: 0.625rem;
    position: relative;
    height: 100%;
    background-color: rgb(220, 219, 219);
}

.grid {
    height: 50px;
    width: 50px;
    background-color: rgb(92, 190, 229);
    box-shadow: none;
}

.pagenation {
    width: 24.375rem;
    float: right;
}
</style>