import { get, post } from './request'

// 删除改造对象名称
// GET /project/deleteProjectName
export const deleteProjectName = (parmas) => {
  return get('/project/deleteProjectName', parmas)
}

// 人员筛选日期查询项目
// /project/queryProjectMsgByTime
export const queryProjectMsgByTime = (parmas) => {
  return get('/project/queryProjectMsgByTime', parmas)
}

// /project/deleteProjectMsg
export const deleteProjectMsg = (parmas) => {
  return get('/project/deleteProjectMsg', parmas)
}

// /project/queryAll 查询所有的项目事件
export const queryAll = (parmas) => {
  return get('/project/queryAll', parmas)
}

// 新增人员
// GET /project/addProjectName
export const addName = (parmas) => {
  return get('/project/addProjectName', parmas)
}

// 根据改造对象id查询改造项目
// GET /project/queryProjectMsgById
export const queryProjectMsgById = (parmas) => {
  return get('/project/queryProjectMsgById', parmas)
}

// 新增人员改造项目
// GET /project/addProjectMsg
export const addProjectMsg = (ProjectMsg) => {
  return post('/project/addProjectMsg', ProjectMsg)
}

export const getList = (parmas) => {
  return get('/project/queryProjectNameList', parmas)
}
