import Vue from 'vue'
import Router from 'vue-router'
import Rili from '@/scheduleCalendar/rili.vue'
import Layout from '@/components/layout.vue'
// import Main from "../components/Main.vue";
import Tabble from '../components/tabble.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/layout'
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/layout/tabble',
    children: [
      {
        path: 'tabble',
        component: Tabble
      },
      {
        path: 'rili',
        component: Rili
      }
    ]
  }
]

const router = new Router({
  routes
})

export default router


//             // this.ProjectMsg = this.ProjectMsg
            // this.ProjectMsg = JSON.stringify({ ProjectMsg: this.ProjectMsg })
            // this.ProjectMsg = ` "${this.ProjectMsg}" `
            // console.log(this.ProjectMsg, 'projectmsg');
            // debugger